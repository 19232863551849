var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2 bg-white"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "auth-inner m-0"
  }, [_c('b-col', {
    staticClass: "d-flex auth-bg px-2 p-lg-5",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-col', {
    staticClass: "px-xl-2 mx-auto",
    attrs: {
      "sm": "8",
      "md": "9",
      "lg": "12"
    }
  }, [_c('br'), _c('a', {
    attrs: {
      "href": "\\"
    }
  }, [_c('h1', {
    staticClass: "brand-text text-primary"
  }, [_vm._v("ELP")])]), _c('br'), _c('br'), _c('b-card-title', {
    staticClass: "mb-1",
    attrs: {
      "title-tag": "h2"
    }
  }, [_vm._v("Daftar")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Lengkapi data-data di bawah ini untuk menyelesaikan pendaftaran ")]), _c('validation-observer', {
    ref: "registerForm",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-register-form mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.register($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nama",
            "label-for": "register-name"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Name",
            "vid": "name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "register-name",
                  "name": "register-name",
                  "state": errors.length > 0 ? false : null,
                  "placeholder": "Masukkan nama"
                },
                model: {
                  value: _vm.name,
                  callback: function callback($$v) {
                    _vm.name = $$v;
                  },
                  expression: "name"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor telepon",
            "label-for": "register-no-telp"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "No Telp",
            "vid": "register_no_telp",
            "rules": "required|min:11"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-input', {
                attrs: {
                  "type": "number",
                  "id": "register-no-telp",
                  "name": "register-no-telp",
                  "state": errors.length > 0 ? false : null,
                  "placeholder": "Masukkan nomor telepon"
                },
                model: {
                  value: _vm.no_telp,
                  callback: function callback($$v) {
                    _vm.no_telp = $$v;
                  },
                  expression: "no_telp"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Alamat Email",
            "label-for": "register-email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "vid": "email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "register-email",
                  "name": "register-email",
                  "state": errors.length > 0 ? false : null,
                  "placeholder": "Masukkan Email"
                },
                model: {
                  value: _vm.userEmail,
                  callback: function callback($$v) {
                    _vm.userEmail = $$v;
                  },
                  expression: "userEmail"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "register-password",
            "label": "Password"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Password",
            "vid": "password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "register-password",
                  "type": _vm.passwordFieldType,
                  "state": errors.length > 0 ? false : null,
                  "name": "register-password",
                  "placeholder": "Masukkan kata sandi"
                },
                model: {
                  value: _vm.password,
                  callback: function callback($$v) {
                    _vm.password = $$v;
                  },
                  expression: "password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIcon
                },
                on: {
                  "click": _vm.togglePasswordVisibility
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "type": "submit",
            "disabled": invalid
          }
        }, [_vm._v(" Daftar ")])], 1)];
      }
    }])
  }), _c('p', {
    staticClass: "text-center mt-2"
  }, [_c('span', [_vm._v("Sudah punya akun?")]), _c('b-link', {
    attrs: {
      "to": {
        name: 'auth-login'
      }
    }
  }, [_c('span', [_vm._v(" Masuk terlebih dahulu")])])], 1), _c('p', {
    staticClass: "clearfix",
    staticStyle: {
      "margin-top": "70px"
    }
  }, [_c('span', {
    staticClass: "float-md-left d-block d-md-inline-block mt-25"
  }, [_vm._v(" © " + _vm._s(new Date().getFullYear()) + " Einstein Learning Plus "), _c('span', {
    staticClass: "d-none d-sm-inline-block"
  }, [_vm._v(" . All rights Reserved ")])])])], 1)], 1), _c('b-col', {
    staticClass: "d-none d-lg-flex align-items-center",
    staticStyle: {
      "height": "90vh"
    },
    attrs: {
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "card bg-dark text-white"
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": _vm.imgUrl,
      "alt": "Background"
    }
  }), _c('div', {
    staticClass: "card-img-overlay container",
    staticStyle: {
      "width": "85%"
    }
  })])])], 1), _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "id": "modal-scoped",
      "centered": "",
      "title": "Using Component Methods"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref6) {
        var close = _ref6.close;
        return [_c('h5', [_vm._v("Verifikasi email")])];
      }
    }, {
      key: "default",
      fn: function fn(_ref7) {
        var hide = _ref7.hide;
        return [_c('h4', [_vm._v(_vm._s(_vm.message))])];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref8) {
        var ok = _ref8.ok,
            cancel = _ref8.cancel,
            hide = _ref8.hide;
        return [_c('b-button', {
          attrs: {
            "size": "lg",
            "block": "",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.visitEmail();
            }
          }
        }, [_vm._v(" Lihat Email ")])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }