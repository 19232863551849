<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-overlay :show="loading">
      <b-row class="auth-inner m-0">
        <!-- Register-->
        <b-col lg="6" class="d-flex auth-bg px-2 p-lg-5">
          <b-col sm="8" md="9" lg="12" class="px-xl-2 mx-auto">
            <br />
            <a href="\" class="">
              <h1 class="brand-text text-primary">ELP</h1>
            </a>
            <br />
            <br />
            <b-card-title class="mb-1" title-tag="h2">Daftar</b-card-title>
            <b-card-text class="mb-2">
              Lengkapi data-data di bawah ini untuk menyelesaikan pendaftaran
            </b-card-text>

            <!-- form -->
            <validation-observer ref="registerForm" #default="{ invalid }">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <!-- name -->
                <b-form-group label="Nama" label-for="register-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="name"
                      name="register-name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan nama"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- no. telepon -->
                <b-form-group
                  label="Nomor telepon"
                  label-for="register-no-telp"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="No Telp"
                    vid="register_no_telp"
                    rules="required|min:11"
                  >
                    <b-form-input
                      type="number"
                      id="register-no-telp"
                      v-model="no_telp"
                      name="register-no-telp"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan nomor telepon"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group label="Alamat Email" label-for="register-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="userEmail"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group label-for="register-password" label="Password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password"
                        placeholder="Masukkan kata sandi"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  Daftar
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>Sudah punya akun?</span>
              <b-link :to="{ name: 'auth-login' }">
                <span>&nbsp;Masuk terlebih dahulu</span>
              </b-link>
            </p>
            <p class="clearfix" style="margin-top: 70px">
              <span class="float-md-left d-block d-md-inline-block mt-25">
                © {{ new Date().getFullYear() }} Einstein Learning Plus
                <span class="d-none d-sm-inline-block">
                  . All rights Reserved
                </span>
              </span>
            </p>
          </b-col>
        </b-col>
        <!-- /Register-->

        <!-- Right Text-->
        <b-col
          lg="6"
          class="d-none d-lg-flex align-items-center"
          style="height: 90vh"
        >
          <div class="card bg-dark text-white">
            <img class="" :src="imgUrl" style="width: 100%" alt="Background" />
            <div class="card-img-overlay container" style="width: 85%">
              <!-- <center>
                <h1 class="text-white text-left" style="margin-top: 370px;">
                  <b>
                    Latihan Ujian
                    <br />
                    Untuk Hasil Berkualitas
                  </b>
                </h1>
                <div class="mt-3 mb-2">
                  <b-avatar-group size="32px">
                    <b-avatar
                      class="pull-up"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-5.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      variant="primary"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-7.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-10.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      variant="danger"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-8.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-20.jpg')
                      "
                    />
                  </b-avatar-group>
                </div>
                <p class="text-left">
                  Alumni yang Sukses Setelah Mengikuti Try Out dari Kami
                </p>
              </center> -->
            </div>
          </div>
          <!-- <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div> -->
        </b-col>
        <!-- /Right Text-->
      </b-row>

      <b-modal
        id="modal-scoped"
        ref="my-modal"
        centered
        title="Using Component Methods"
      >
        <template #modal-header="{ close }">
          <h5>Verifikasi email</h5>
        </template>

        <template #default="{ hide }">
          <h4>{{ message }}</h4>
        </template>

        <template #modal-footer="{ ok, cancel, hide }">
          <b-button size="lg" block variant="info" @click="visitEmail()">
            Lihat Email
          </b-button>
        </template>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BOverlay,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BAvatarGroup,
  BAvatar,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BOverlay,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BAvatarGroup,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      name: "",
      no_telp: "",
      userEmail: "",
      password: "",
      message: "",
      sideImg: require("@/assets/images/elp/bg-login.png"),
      loading: false,
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    visitEmail() {
      window.location.href = "https://mail.google.com/mail/u/0/#inbox";
    },
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const payload = {
            name: this.name,
            no_telp: this.no_telp,
            email: this.userEmail,
            password: this.password,
          };
          this.$store
            .dispatch("auth/register", payload)
            .then((res) => {
              this.loading = false;
              this.message = res.data.message;
              this.name = "";
              this.no_telp = "";
              this.userEmail = "";
              this.password = "";

              this.$refs["registerForm"].reset();
              this.$refs["my-modal"].show();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Peringatan!`,
                  icon: "AlertCircleIcon",
                  variant: "warning",
                  text: "Silahkan verifikasi email terlebih dahulu",
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$refs.registerForm.setErrors(error);
              this.displayError(error);
              return false;
            });

          // useJwt
          //   .register({
          //     name: this.name,
          //     no_telp: this.no_telp,
          //     email: this.userEmail,
          //     password: this.password,
          //   })
          //   .then((response) => {
          //     useJwt.setToken(response.data.accessToken);
          //     useJwt.setRefreshToken(response.data.refreshToken);
          //     localStorage.setItem("userData", JSON.stringify(response.data.userData));
          //     this.$ability.update(response.data.userData.ability);
          //     this.$router.push("/");
          //   })
          //   .catch((error) => {
          //     this.$refs.registerForm.setErrors(error.response.data.error);
          //   });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
